<script setup>
const config = useRuntimeConfig();

</script>
<template>
    <div :class="{ 'container-lg': !config.public.isDomino }">
        <footer>
            <div class="container-lg">
                <div class="row row-cols-1 row-cols-md-4 g-3">
                    <div class="col">
                        <a class="brand" href="/">
                            <img src="/images/footer-logo.png" :alt="config.public.appName" class="logo">
                        </a>
                    </div>
                    <div class="col">
                        <div class="footer-item">
                            Скачать приложение:
                            <div v-if="config.public.isDomino" class="d-flex flex-column flex-wrap gap-1">
                                <a href="https://play.google.com/store/apps/details?id=ru.dpizza.app" target="_blank">
                                    <img src="/images/googleplay.png" alt="" style="width: 100%; max-width: 160px;">
                                </a>

                                <a href="https://apps.apple.com/us/app/domino-доставка-пиццы/id6461727750"
                                    target="_blank">
                                    <img src="/images/appstore.png" alt="" style="width: 100%; max-width: 160px;">
                                </a>

                                <a href="https://appgallery.huawei.ru/app/C109083627" target="_blank">
                                    <img src="/images/appgallery.png" alt="" style="width: 100%; max-width: 160px;">
                                </a>

                                <a href="https://apps.rustore.ru/app/ru.dominopizza.app" target="_blank">
                                    <img src="/images/rustore.png" alt=""
                                        style="width: 100%; max-width: 165px; height: 52px; margin-left: -2px; margin-top: -1px;">
                                </a>
                            </div>
                            <div v-else class="d-flex flex-column flex-wrap gap-1">
                                <a href="https://play.google.com/store/apps/details?id=ru.a4pizza.app" target="_blank">
                                    <img src="/images/googleplay.png" alt="" style="width: 100%; max-width: 160px;">
                                </a>

                                <a href="https://apps.apple.com/us/app/a4-pizza/id6452048069" target="_blank">
                                    <img src="/images/appstore.png" alt="" style="width: 100%; max-width: 160px;">
                                </a>

                                <a href="https://appgallery.huawei.ru/app/C108871169" target="_blank">
                                    <img src="/images/appgallery.png" alt="" style="width: 100%; max-width: 160px;">
                                </a>

                            </div>
                        </div>

                    </div>
                    <div v-if="false" class="col">
                        <div class="d-flex flex-column">
                            <div class="footer-item">
                                Подпишись на нас:
                                <div class="d-flex flex-row">
                                    <!-- <a href="" class="me-1">
                                    <img src="/images/vk-icon.png" width="32" alt="">
                                </a> -->
                                    <a href="https://t.me/A4pizzapizza" class="me-1" target="_blank">
                                        <img src="/images/tg-icon.png" width="32" alt="">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col">
                        <div class="d-flex flex-column">
                            <div v-if="config.public.isDomino" class="footer-item">
                                <NuxtLink class="footer-link" to="/akcii">Акции</NuxtLink>
                            </div>
                            <div v-if="config.public.isDomino" class="footer-item">
                                <NuxtLink class="footer-link" to="/contacts">Контакты</NuxtLink>
                            </div>
                            <div v-if="config.public.isDomino" class="footer-item">
                                <NuxtLink class="footer-link" href="https://rabota.dominopizza.ru">Работа</NuxtLink>
                            </div>
                            <div class="footer-item">
                                <a class="footer-link" href="/rules">Условия доставки</a>
                            </div>
                            <div class="footer-item">
                                <NuxtLink class="footer-link" to="/policy" target="_blank">Политика
                                    конфиденциальности</NuxtLink>
                            </div>
                            <div class="footer-item">
                                <NuxtLink class="footer-link" to="/oferta">Публичная оферта</NuxtLink>
                            </div>
                            <div v-if="config.public.isDomino" class="footer-item">
                                <a href="/files/oferta-loyalty.pdf" class="footer-link">Правила системы лояльности</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<style lang="scss" scoped>
footer {
    background-color: #fff;
    padding: 40px 0;
    // border: 1px solid #000;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.01);

    @if not $is-domino {
        box-shadow: none;
        border: 1px solid #000;
        border-radius: 10px;
    }

    .brand {
        margin-left: 20px;

        img {
            width: 100%;
            max-width: 200px;
        }
    }

    .footer-item {
        color: #646464;
        font-size: 14px;
        text-decoration: none;

        .footer-link {
            font-size: 14px;
            text-decoration: none;

            color: #646464;

            &:hover {
                color: #000000a6;
            }
        }
    }
}
</style>